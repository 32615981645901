/**
 * @file
 * Visual styles for animated throbber.
 *
 * @see autocomplete.js
 */

.js input.form-autocomplete {
  background-image: url(../../../images/core/throbber-inactive.png);
  background-position: 100% center; /* LTR */
  background-repeat: no-repeat;
}
.js[dir="rtl"] input.form-autocomplete {
  background-position: 0% center;
}
.js input.form-autocomplete.ui-autocomplete-loading {
  background-image: url(../../../images/core/throbber-active.gif);
  background-position: 100% center; /* LTR */
}
.js[dir="rtl"] input.form-autocomplete.ui-autocomplete-loading {
  background-position: 0% center;
}