/**
 * @file
 * Styles for item list.
 */

.item-list__comma-list,
.item-list__comma-list li {
  display: inline;
}
.item-list__comma-list {
  margin: 0;
  padding: 0;
}
.item-list__comma-list li:after {
  content: ", ";
}
.item-list__comma-list li:last-child:after {
  content: "";
}