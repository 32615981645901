.gva-parallax-background {
  position: relative;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  overflow: hidden;
}
.gva-parallax-background .bb-inner {
  position: relative;
  z-index: 9;
}
.gva-parallax-background .gva-parallax-inner {
  height: 150%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background-attachment: scroll;
  background-color: transparent;
  background-image: inherit;
  z-index: 0;
  background-position: 50% 0;
}

.bg-size-cover {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.bg-size-contain {
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
}

.gbb-row-wrapper {
  position: relative;
}
.gbb-row-wrapper .icon-row {
  width: 56px;
  height: 56px;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -28px;
  z-index: 9;
  line-height: 56px;
  text-align: center;
  color: #000;
  font-size: 22px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}
.gbb-row-wrapper .icon-row:after {
  content: "";
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  border: 6px solid #ccc;
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  z-index: 1;
}
